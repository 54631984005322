import { ref, watch } from 'vue';

export function createPrefixedLocalStorageKey(name: string): string {
  return `app.payment-${name}`;
}

export function localStorageRef<T>(key: string) {
  const prefixedKey = createPrefixedLocalStorageKey(key);
  const initValue = localStorage.getItem(prefixedKey);
  const initValueParsed = initValue ? (JSON.parse(initValue) as T) : null;

  const valueRef = ref<T | null>(initValueParsed);

  watch(
    () => valueRef.value,
    (to) => {
      localStorage.setItem(prefixedKey, JSON.stringify(to));
    },
    { deep: true }
  );

  return valueRef;
}
