export type PaymentStatus = 'Paid' | 'Unpaid' | 'Partially Paid';

export enum PaymentMethod {
  BANK_TRANSFER = 'Bank_Transfer',
  CASH = 'CASH',
  COMPANY_DISPOSAL = 'PAYMENT_TYPE-3-19',
  CONVERSION = 'PAYMENT_TYPE-3-18',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_CARD_ADYEN = 'Credit_Card_Adyen',
  CREDIT_NODE = 'Credit Note',
  CROSS_BORDER = 'CROSS_BORDER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  DIRECT_DEBIT_EGENCIA = 'Direct_Debit_Egencia',
  DIRECT_DEBIT_SUPPLIER = 'Debit_Direct_Supplier',
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  DNU_CHECK = 'DNU_CHECK',
  FLYWIRE = 'Flywire',
  LEASE_IFRS16 = 'PAYMENT_TYPE-3-17',
  LOAN_COMPANY = 'Loan_Company',
  MANUAL = 'MANUAL',
  PAYPAL = 'PayPal',
  SEPA = 'SEPA',
  STRIPE = 'Stripe',
}

export type Invoice = {
  id: number;
  number: string;
  amount: number;
  amountDue: number;
  currency: string;
  date: string;
  type: string;
  status: string;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
  pdf: string | null;
};
