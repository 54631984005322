const validEnvironments = ['dev', 'stage', 'prod'] as const;

type ValidEnvironment = typeof validEnvironments[number];

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const environment = import.meta.env.VITE_BUILD_ENV;

/**
 * Get info about the current environment.
 * - The current environment app.core is running on
 * - If the current environment is valid.
 */
export function getEnvironment():
  | { environment: string; isValid: false }
  | {
      environment: ValidEnvironment;
      isValid: true;
    } {
  const currentEnvironment = String(environment);

  const resultingEnvironment = validEnvironments.find(
    (env) => env === currentEnvironment
  );

  if (resultingEnvironment === undefined) {
    return {
      environment: currentEnvironment,
      isValid: false,
    };
  }
  return {
    environment: resultingEnvironment,
    isValid: true,
  };
}
