export enum PaymentMethodEnum {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  STUDENT_LOAN = 'STUDENT_LOAN',
}

export type CreditCard = {
  type: PaymentMethodEnum.CREDIT_CARD;
  hasActiveCreditCard: boolean;
  translationKey: string;
  expiryDate: string | null;
  lastFourDigits: string | null;
};

export type BankTransfer = {
  type: PaymentMethodEnum.BANK_TRANSFER;
  translationKey: string;
};

export type StudentLoan = {
  type: PaymentMethodEnum.STUDENT_LOAN;
  translationKey: string;
};

export type PaymentMethod = CreditCard | BankTransfer | StudentLoan;
