import { defineStore } from 'pinia';
import { getInvoices as getApiInvoices } from '@/api/requests';
import { Invoice } from '@/types/Invoice';
import { captureException } from '@/utils/logging';

type InvoicesState = {
  invoices: Invoice[];
};

export const useInvoicesStore = defineStore('invoices', {
  // arrow function recommended for full type inference
  state(): InvoicesState {
    return { invoices: [] };
  },
  actions: {
    async init() {
      try {
        this.invoices = await getApiInvoices();
      } catch (error) {
        captureException(error);
        this.invoices = [];
      }
    },
  },
  getters: {
    getInvoices(): Invoice[] {
      return this.invoices;
    },
  },
});
