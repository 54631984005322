<template>
  <HandleAuth0 />
  <!-- we need suspense since we use top level awaits: https://vuejs.org/guide/built-ins/suspense.html -->
  <main v-if="auth.isLoggedIn">
    <Suspense>
      <InitAppView />
      <template #fallback>
        <LoadingView />
      </template>
    </Suspense>
  </main>
</template>

<script setup lang="ts">
import { onBeforeMount, watch } from 'vue';
import * as Sentry from '@sentry/vue';
import { useRoute, useRouter } from 'vue-router';
import LoadingView from '@/views/LoadingView.vue';
import InitAppView from '@/views/InitAppView.vue';
import HandleAuth0 from '@/components/HandleAuth0.vue';
import { auth } from '@/utils/auth';
import { shouldLog } from '@/plugins/sentry';

const route = useRoute();
const router = useRouter();

onBeforeMount(async () => {
  await router.isReady();
  const isLoggedIn = await auth.internal.hasSSOSession();
  if (route.fullPath.includes('logout')) {
    await auth.internal.logout();
  } else if (!isLoggedIn) {
    await auth.internal.login();
    await router.replace('/');
  }

  watch(auth.user, (user) => {
    if (user && shouldLog()) {
      Sentry.setUser({ academy5Id: user?.academy5Id });
    }
  });
});
</script>
