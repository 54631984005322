import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { getEnvironment } from '@/utils/environment';
import packageJson from '@/../package.json';

const { environment } = getEnvironment();
const { version: packageVersion } = packageJson;

export const shouldLog = (): boolean => {
  return import.meta.env.VITE_SENTRY_ENABLED === 'true';
};

export function initializeSentry(app?: App): void {
  if (shouldLog()) {
    Sentry.init({
      Vue: app,
      dsn: 'https://24d7956d9c3a20c34a274b7177ad6a28@o299618.ingest.sentry.io/4505867478827008',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 0.1,
      environment,
      release: packageVersion,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    // eslint-disable-next-line no-console
    console.info('Sentry has not been loaded.');
  }
}
