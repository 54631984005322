import * as Sentry from '@sentry/vue';
import { shouldLog } from '@/plugins/sentry';
/** Logs the message to sentry with severity level 'error'
 * */
export function captureErrorMessage(message: string) {
  if (shouldLog()) {
    return Sentry.captureMessage(message, 'error');
  }
  return null;
}

/** Logs the
 *  message to sentry with severity level 'warning'
 * */
export function captureWarningMessage(message: string) {
  if (shouldLog()) {
    return Sentry.captureMessage(message, 'warning');
  }
  return null;
}

/** Logs the error as an exception to sentry */
export function captureException(error: Error | any) {
  if (error instanceof Error && shouldLog()) {
    return Sentry.captureException(error);
  }

  return null;
}
