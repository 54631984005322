import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import { auth0Plugin } from '@/plugins/auth0';
import { i18nPlugin } from '@/plugins/i18n';
import { initializeSentry } from '@/plugins/sentry';
import { router } from '@/router';

// eslint-disable-next-line no-restricted-imports
import '@/assets/main.css';

// eslint-disable-next-line no-restricted-imports
import '@/assets/tailwind/tailwind.css';

const pinia = createPinia();
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

initializeSentry(app);

app.use(router).use(i18nPlugin).use(auth0Plugin).use(pinia);
app.mount('#app');
