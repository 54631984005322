import {
  Invoice as ApiInvoice,
  PaymentStatus as ApiPaymentStatus,
  PaymentMethod as ApiPaymentMethod,
} from '@/types/api/Invoice';
import { Invoice, PaymentStatus } from '@/types/Invoice';

function mapPaymentStatus(
  paymentStatus: ApiPaymentStatus
): PaymentStatus | null {
  switch (paymentStatus) {
    case 'Paid':
      return 'paid';
    case 'Unpaid':
      return 'unpaid';
    case 'Partially Paid':
      return 'partiallyPaid';
    default:
      return null;
  }
}

// eslint-disable-next-line complexity
function mapPaymentMethod(paymentMethod: ApiPaymentMethod): string {
  switch (paymentMethod) {
    case ApiPaymentMethod.CREDIT_CARD:
    case ApiPaymentMethod.CREDIT_CARD_ADYEN:
      return 'creditCard';
    case ApiPaymentMethod.LOAN_COMPANY:
      return 'studentLoan';
    case ApiPaymentMethod.BANK_TRANSFER:
      return 'bankTransfer';
    case ApiPaymentMethod.DIRECT_DEBIT:
    case ApiPaymentMethod.DIRECT_DEBIT_EGENCIA:
    case ApiPaymentMethod.DIRECT_DEBIT_SUPPLIER:
      return 'directDebit';
    default:
      return paymentMethod;
  }
}

export function mapInvoicesResponse(invoiceList: ApiInvoice[]): Invoice[] {
  return invoiceList.map((invoice) => ({
    id: invoice.number,
    date: new Date(invoice.date),
    type: invoice.type,
    amount: invoice.amount / 100,
    amountDue: invoice.amountDue / 100,
    currency: invoice.currency,
    paymentStatus: mapPaymentStatus(invoice.paymentStatus),
    paymentMethod: mapPaymentMethod(invoice.paymentMethod),
    pdfLink: invoice.pdf,
  }));
}
