import { Ref, ref } from 'vue';
import axios, {
  CreateAxiosDefaults,
  AxiosHeaders,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { auth } from '@/utils/auth';

const defaultBaseURL = import.meta.env.VITE_API_URL;
if (!defaultBaseURL) {
  throw new Error('VITE_API_URL is missing in environment');
}

const useAuthenticatedClient = ({
  baseURL = defaultBaseURL,
  ...config
}: CreateAxiosDefaults) => {
  const client = axios.create({
    baseURL,
    ...config,
  });

  client.interceptors.request.use(async (requestConfig) => {
    const authToken = await auth.getAccessToken();

    const headers = new AxiosHeaders({
      ...requestConfig.headers,
      Authorization: `Bearer ${authToken}`,
    });

    return {
      ...requestConfig,
      headers,
    };
  });

  return {
    client,
  };
};

const useAuthenticatedRequest = <Response = any>({
  baseURL = defaultBaseURL,
  method = 'get',
  ...config
}: AxiosRequestConfig): {
  data: Ref<Response | undefined>;
  error: Ref<AxiosError | undefined>;
  request: Promise<AxiosResponse<Response> | AxiosError>;
  loading: Ref<boolean>;
} => {
  const data = ref<Response>();
  const error = ref<AxiosError>();
  const loading = ref(true);

  let promiseResolve: (value: AxiosResponse<Response> | AxiosError) => void;

  const request: Promise<AxiosResponse<Response> | AxiosError> = new Promise(
    (resolve) => {
      promiseResolve = resolve;
    }
  );

  const { client } = useAuthenticatedClient({
    baseURL,
  });

  client
    .request<Response>({ method, ...config })
    .then((response: AxiosResponse<Response>) => {
      data.value = response.data;
      promiseResolve(response);
    })
    .catch((err: AxiosError) => {
      error.value = err;
      promiseResolve(err);
    })
    .finally(() => {
      loading.value = false;
    });

  return { data, error, request, loading };
};

const createHttpClient = (baseURL: string) => {
  const { client } = useAuthenticatedClient({ baseURL });

  // TODO make this mapping more generic/dynamic
  const adjustedRequest = async (
    config: AxiosRequestConfig & {
      path?: string;
      query?: Record<string, string>;
    }
  ): Promise<AxiosResponse> => {
    const { path, query } = config;
    const newConfig: AxiosRequestConfig = {};

    if (path) {
      newConfig.url = path;
    }
    if (query) {
      newConfig.params = query;
    }

    return client.request({ ...config, ...newConfig });
  };

  return { ...client, request: adjustedRequest };
};

interface BasicHttpClient {
  instance: {
    getUri: () => string | undefined;
  };
}

const useAuthenticatedApi = <
  // To allow generics to be new-able, we need to extend them this way
  ApiType extends new (...args: any) => InstanceType<ApiType>,
  ClientInstance extends BasicHttpClient,
  ClientType extends new (...args: any) => ClientInstance
>(
  Api: ApiType,
  HttpClient: ClientType,
  { baseURL = defaultBaseURL }: AxiosRequestConfig
): InstanceType<ApiType> => {
  // Configure base URL based off of app.core environment and API base URL
  let newBaseUrl: string;
  const apiBaseUrl = new HttpClient().instance.getUri();

  if (apiBaseUrl === undefined) {
    newBaseUrl = baseURL;
  } else {
    const urlBuilder = new URL(apiBaseUrl);
    const baseUrl = new URL(baseURL);

    baseUrl.pathname = urlBuilder.pathname;

    newBaseUrl = baseUrl.toString();
  }

  const authenticatedClient = createHttpClient(newBaseUrl);

  return new Api(authenticatedClient);
};

/**
 * API utilities
 */
export const api = {
  /**
   * Creates a new axios instance with the given config. Additionaly it will set
   * a default "Authorization" header with the current token. If the token is not yet
   * available, requests will wait for it to be set.
   *
   * @param config - Axios config
   * @returns Axios instance
   */
  useAuthenticatedClient,

  /**
   * Executes a request with the given config. In addition to the axios config, it
   * also sets a default "Authorization" header with the current token. If the token
   * is not yet available, it will wait for it to be set.
   *
   * @param config - Axios Request config
   * @returns The Axios request, a ref of the data of the response and a ref of an error (if applicable)
   */
  useAuthenticatedRequest,

  /**
   * Instantiates a new API class with a given config that was generated from a backend service with the @iu/open-api library.
   *
   * Will use an authenticated client to make all requests.
   *
   * @param Api - The API class generated by `@iu/open-api`
   * @param HttpClient - The HTTP client class generated by `@iu/open-api` (only used to get the services URL prefix)
   * @param config - Axios config overrides, e.g. to change the base URL
   */
  useAuthenticatedApi,
};
