import { computed, Ref, ref, unref, watch } from 'vue';
import type { Auth0VueClient } from '@auth0/auth0-vue';
import { loginRoute, logoutRedirectUri, logoutRoute } from '@/plugins/auth0';

const refOfUserRef: Ref<null | Auth0VueClient['user']> = ref(null);

const userInfo = computed(() => {
  const userRef = unref(refOfUserRef);
  if (userRef === null) {
    return null;
  }
  return unref(userRef);
});

export type User = {
  firstName: string;
  lastName: string;
  fullName: string;
  pictureUrl: string;
  email: string;
  academy5Id: string;
};

function ensureValueExistsInToken(
  tokenUserData: Auth0VueClient['user']['value'],
  key: keyof Auth0VueClient['user']['value']
): string {
  const value = tokenUserData[key] as unknown;

  if (typeof value !== 'string') {
    throw new Error(
      `Expected attribute "${key}" to exist in the token and contain a string.`
    );
  }
  return value;
}

const user = computed<User | null>(() => {
  const userRef = unref(refOfUserRef);
  if (userRef === null) {
    return null;
  }
  const tokenUserData = unref(userRef);

  const userData = {
    firstName: ensureValueExistsInToken(tokenUserData, 'given_name'),
    lastName: ensureValueExistsInToken(tokenUserData, 'family_name'),
    fullName: ensureValueExistsInToken(tokenUserData, 'name'),
    pictureUrl: ensureValueExistsInToken(tokenUserData, 'picture'),
    email: ensureValueExistsInToken(tokenUserData, 'email'),
    academy5Id: ensureValueExistsInToken(tokenUserData, 'academy_id'),
  };

  return userData;
});

const isLoggedIn = computed(() => {
  return unref(user) !== null;
});

function shareThatUserIsLoggedIn(userRef: Auth0VueClient['user']): void {
  refOfUserRef.value = userRef;
}

function shareThatUserIsLoggedOut(): void {
  refOfUserRef.value = null;
}

// To enable us calling hasSSOSession from outside the auth0 plugin
type Auth0Helpers = {
  getAccessTokenSilently: Auth0VueClient['getAccessTokenSilently'];
  user: Auth0VueClient['user'];
  handleRedirectCallback: Auth0VueClient['handleRedirectCallback'];
  logout: Auth0VueClient['logout'];
  loginWithRedirect: Auth0VueClient['loginWithRedirect'];
};

let resolveAuth0Helpers: (value: Auth0Helpers) => void;

const auth0Helpers = new Promise<Auth0Helpers>((resolve) => {
  resolveAuth0Helpers = resolve;
});

export function setAuth0Helpers(helpers: Auth0Helpers) {
  resolveAuth0Helpers(helpers);
}

function waitForLoggedIn(): Promise<void> {
  return new Promise((resolve) => {
    if (isLoggedIn.value) {
      resolve();
      return;
    }

    const unsubscribe = watch(isLoggedIn, (value) => {
      if (value) {
        unsubscribe();
        resolve();
      }
    });
  });
}

/**
 * Get a valid access token for the user without side effects like redirecting to auth0.
 * To enable this behavior, it will wait for the user to be logged in.
 */
async function getAccessToken() {
  await waitForLoggedIn();
  const { getAccessTokenSilently } = await auth0Helpers;
  return getAccessTokenSilently();
}

async function hasSSOSession(): Promise<boolean> {
  const { getAccessTokenSilently, user: userRef } = await auth0Helpers;

  try {
    await getAccessTokenSilently();
    shareThatUserIsLoggedIn(userRef);
    return true;
  } catch {
    shareThatUserIsLoggedOut();
    return false;
  }
}

async function logout() {
  const { logout: auth0Logout } = await auth0Helpers;
  shareThatUserIsLoggedOut();

  await auth0Logout({
    logoutParams: {
      returnTo: logoutRedirectUri,
    },
  });
}

async function handleRedirectCallback() {
  const { handleRedirectCallback: auth0HandleRedirectCallback } =
    await auth0Helpers;
  await auth0HandleRedirectCallback();
}

async function login(options?: { locale?: string }) {
  const { loginWithRedirect, user: userRef } = await auth0Helpers;
  const redirectOptions: Parameters<typeof loginWithRedirect>[0] = {
    authorizationParams: {
      ui_locales: options?.locale,
    },
  };
  try {
    await handleRedirectCallback();
    shareThatUserIsLoggedIn(userRef);
  } catch {
    await loginWithRedirect(redirectOptions);
  }
}

/**
 * Authentification utilities
 */
export const auth = {
  /**
   * A boolean that indicates whether the user is currently logged in.
   */
  isLoggedIn,

  /**
   *  @deprecated use `user` instead for validated properties
   *  Auth0 information about the current user
   */
  userInfo,

  /**
   * A collection of user info from the idToken.
   * Use this to get information about the logged in user.
   */
  user,

  /**
   * Get a valid access token for the user.
   * It will wait for the user to be logged in.
   */
  getAccessToken,

  /**
   *  For use only within @iu/myiu.fe.app.auth
   */
  internal: {
    /**
     * Only use this in onMounted or later in the lifecycle, since it triggers DOM node interactions.
     */
    hasSSOSession,

    /**
     * Log out the current user.
     * @param options.localOnly if true, only the local session is cleared and no redirect happens. Defaults to false.
     */
    logout,

    /**
     * Log in via redirect to auth0 SSO page.
     * @param options.locale if set, the auth0 page will be shown in that language. Otherwise, auth0 will determine the language itself.
     */
    login,

    /**
     * Use this to handle the redirect callback after OAuth login.
     */
    handleRedirectCallback,

    /**
     * Routes to login and logout
     */
    routes: {
      login: loginRoute,
      logout: logoutRoute,
    },
  },
};
