import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const baseURL = '/';

const routes: Array<RouteRecordRaw> = [
  {
    path: `${baseURL}`,
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: `${baseURL}/home`,
    redirect: { name: 'home' },
  },
  {
    path: `${baseURL}/oidc-callback`,
    name: 'oidc-callback',
    component: () => import('@/views/LoginView.vue'),
  },
  // Remove this route in the end, since we don't want to log out users
  {
    path: `${baseURL}/logout`,
    name: 'logout',
    component: () => import('@/views/LogoutView.vue'),
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
