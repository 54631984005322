import { i18n } from '@/utils/i18n';
import { api } from '@/utils/api';

export const axiosInstance = api.useAuthenticatedClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  baseURL: import.meta.env.VITE_API_URL,
}).client;

axiosInstance.interceptors.request.use((config) => {
  return {
    ...config,
    baseURL: `${config.baseURL || ''}`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    params: {
      ...config.params,
      locale: i18n.locale.value,
    },
  };
});
