import locizer, { TranslationRecord } from 'locizer';
import { readonly, ref } from 'vue';
import { initialize } from '@iu/oxy';
import { i18nPlugin } from '@/plugins/i18n';

type NamespaceInformation =
  | { successful: false; messages: undefined }
  | { successful: true; messages: TranslationRecord };

const loadedNamespaces = new Map<string, Promise<NamespaceInformation>>();

const internalAvailableLocales = ref(i18nPlugin.global.availableLocales);

export function updateAvailableLocales() {
  internalAvailableLocales.value = i18nPlugin.global.availableLocales;
}

const exposedAvailableLocales = readonly(internalAvailableLocales);

function loadMessagesOfNamespace(
  namespace: string
): Promise<TranslationRecord> {
  return new Promise((resolve, reject) => {
    locizer.loadAll(namespace, (err: Error, messages: TranslationRecord) => {
      if (err) {
        return reject(err);
      }
      Object.entries(messages).forEach(([key, message]) => {
        if (message) {
          i18nPlugin.global.mergeLocaleMessage(key, { [namespace]: message });
          // TODO remove this deprecated shared instance
          // externalI18nPlugin.global.mergeLocaleMessage(key, {
          //   [namespace]: message,
          // });
        }
      });
      // Since vue-i18n's `availableLocales` is not reactive, we need to make sure we keep it up to date.
      updateAvailableLocales();
      return resolve(messages);
    });
  });
}

async function attemptToLoadMessagesOfNamespace(
  namespace: string
): Promise<NamespaceInformation> {
  try {
    const messages = await loadMessagesOfNamespace(namespace);
    return { successful: true, messages };
  } catch (error) {
    // If we fail, we do not want to keep the promise. This way we can try again and do not waste storage on failed promises.
    loadedNamespaces.delete(namespace);
    return { successful: false, messages: undefined };
  }
}

// Keeping this function synchronous makes sure we do not start two loading processes for the same namespace.
export function waitForTranslationsOfNamespace(
  namespace: string
): Promise<NamespaceInformation> {
  const existingNamespace = loadedNamespaces.get(namespace);
  if (existingNamespace) {
    return existingNamespace;
  }

  // We do not await here as the promise should be awaited in the respective MFE.
  const loadedMessagesOfNamespace = attemptToLoadMessagesOfNamespace(namespace);
  loadedNamespaces.set(namespace, loadedMessagesOfNamespace);

  return loadedMessagesOfNamespace;
}

// Initialize oxy so that its internal i18n locale is synced to our locale
initialize({
  i18n: {
    locale: i18nPlugin.global.locale,
  },
});

/**
 * I18n utilities
 */
export const i18n = {
  /** Request the translations from a specific namespace
   *
   * @returns a promise that will resolve once the translations are either available, or the request has failed.
   * Check .successful to see which case it is.
   *
   * If it was successful, .messages will contain the translations. They will automatically be available in the vue-i18n instance.
   * */
  waitForTranslationsOfNamespace,

  // Direct proxies to the vue-i18n instance
  /**
   * The current locale
   */
  locale: i18nPlugin.global.locale,

  /**
   * Translation
   */
  t: i18nPlugin.global.t,

  /**
   * Datetime formatting
   */
  d: i18nPlugin.global.d,

  /**
   * Number formatting
   */
  n: i18nPlugin.global.n,

  /**
   * The available locales to choose from
   */
  availableLocales: exposedAvailableLocales,
};

// TODO remove this deprecated utility
/** @deprecated
 * Use `fe.app.core.i18n` instead
 */
export const translations = { waitForTranslationsOfNamespace };
