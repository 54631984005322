import { createAuth0 } from '@auth0/auth0-vue';

const env = import.meta.env as Record<string, string | undefined>;

const domain = env.VITE_AUTH0_DOMAIN;
const clientId = env.VITE_AUTH0_CLIENT_ID;
const audience = env.VITE_AUTH0_AUDIENCE;
if (!domain || !clientId || !audience) {
  throw new Error('Auth0 env variables missing!');
}
const redirectBaseUri = window.location.origin;

const callbackUri = 'oidc-callback';

export const loginRoute = '';
export const logoutRoute = 'logout';

const logoutUri = new URL(loginRoute, redirectBaseUri);
logoutUri.searchParams.set('loggedOut', 'true');

export const logoutRedirectUri = logoutUri.toString();

export const auth0Plugin = createAuth0(
  {
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: new URL(callbackUri, redirectBaseUri).toString(),
      audience,
    },
    cacheLocation: 'memory', // It's the default, but let's be explicit, since we need this for Single-Log-Out to work
    useRefreshTokens: true, // This way we don't use the SSO iframe magic when refreshing tokens during user sessions
    useRefreshTokensFallback: true, // We need this so that we use the SSO when refreshing tokens between user sessions
  },
  {
    skipRedirectCallback: true,
  }
);
