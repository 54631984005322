import { axiosInstance as axios } from '@/plugins/axios';
import { AdyenSession, AdyenTokenizeResponse } from '@/types/AdyenSession';
import { mapSessionResponse } from '@/api/mappers/sessionMapper';
import { mapPaymentDetailsResponse } from '@/api/mappers/paymentDetailsMapper';
import { Session } from '@/types/api/Session';
import { PaymentMethod } from '@/types/PaymentMethod';
import { PaymentDetails } from '@/types/api/PaymentDetails';
import { Invoice as ApiInvoice } from '@/types/api/Invoice';
import { Invoice } from '@/types/Invoice';
import { mapInvoicesResponse } from '@/api/mappers/invoiceMapper';

export async function getAdyenSession(): Promise<AdyenSession> {
  try {
    const response = await axios.get<Session>('/payment/api/adyen/session');
    return mapSessionResponse(response.data);
  } catch (error) {
    throw new Error('Could not get adyen session', {
      cause: error as Error,
    });
  }
}

export async function getAdyenToken(
  payload: any
): Promise<AdyenTokenizeResponse> {
  try {
    const response = await axios.post(
      '/payment/api/adyen/tokenize',
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data as AdyenTokenizeResponse;
  } catch (error) {
    throw new Error('Could not get adyen token', {
      cause: error as Error,
    });
  }
}

export async function getPaymentDetails(): Promise<PaymentMethod> {
  try {
    const response = await axios.get<PaymentDetails>(
      '/payment/api/payment-details'
    );
    return mapPaymentDetailsResponse(response.data);
  } catch (error) {
    throw new Error('Could not get payment method', {
      cause: error as Error,
    });
  }
}

export async function getInvoices(): Promise<Invoice[]> {
  try {
    const response = await axios.get<ApiInvoice[]>('/payment/api/invoices');
    return mapInvoicesResponse(response.data);
  } catch (error) {
    throw new Error('Could not get invoices', {
      cause: error as Error,
    });
  }
}

export async function getPdf(url: string): Promise<Blob> {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    return new Blob([response.data], { type: 'application/pdf' });
  } catch (error) {
    throw new Error('Could not get invoice pdf', {
      cause: error as Error,
    });
  }
}

export async function getInvoiceYears(): Promise<string[]> {
  const response = await axios.get<string[]>('/payment/api/invoices/years');
  return response.data;
}
