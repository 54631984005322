import { defineStore } from 'pinia';
import { getInvoiceYears } from '@/api/requests';
import { captureException } from '@/utils/logging';

type YearsState = {
  years: string[];
};

export const useYearsStore = defineStore('years', {
  state(): YearsState {
    return { years: [] };
  },
  actions: {
    async init() {
      try {
        this.years = await getInvoiceYears();
      } catch (error) {
        captureException(error);
        this.years = [];
      }
    },
  },
  getters: {
    getYears(): string[] {
      return this.years;
    },
  },
});
