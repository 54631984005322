import { createI18n } from 'vue-i18n';
import locizer from 'locizer';
import { createPrefixedLocalStorageKey } from '@/utils/localStorage';
import { getEnvironment } from '@/utils/environment';

enum LocizeEnvironment {
  LATEST = 'latest',
  STAGE = 'stage',
  PROD = 'prod',
}

const locizeProjectId = '789b3d31-67e6-46ab-8517-123b6e3fb4da';

const fallbackLocale = 'en';

function mapEnvironmentToLocizeEnvironment(): LocizeEnvironment {
  const { environment, isValid } = getEnvironment();

  if (!isValid) {
    return LocizeEnvironment.LATEST;
  }

  if (environment === 'stage') {
    return LocizeEnvironment.STAGE;
  }
  if (environment === 'prod') {
    return LocizeEnvironment.PROD;
  }

  return LocizeEnvironment.LATEST;
}

locizer.init({
  projectId: locizeProjectId,
  loadIfTranslatedOver: 0.01,
  version: mapEnvironmentToLocizeEnvironment(),
});

const localeLocalStorageKey = createPrefixedLocalStorageKey('chosen-locale');

export function persistLocale(locale: string): void {
  localStorage.setItem(localeLocalStorageKey, locale);
}

function getDefaultLocale(): string {
  const storedLocale = localStorage.getItem(localeLocalStorageKey);
  if (storedLocale) {
    return storedLocale;
  }
  const browserLocale = locizer.lng?.slice(0, 2);
  if (browserLocale) {
    return browserLocale;
  }
  return fallbackLocale;
}

export const i18nPlugin = createI18n({
  legacy: false,
  locale: getDefaultLocale(),
  fallbackLocale,
});
