import { PaymentMethod, PaymentMethodEnum } from '@/types/PaymentMethod';
import { PaymentDetails } from '@/types/api/PaymentDetails';

const mapLastFourDigits = (lastFourDigits?: string): string | null => {
  if (lastFourDigits && lastFourDigits.length === 4) {
    return `**** **** **** ${lastFourDigits}`;
  }
  return null;
};

const mapCreditCard = (paymentDetails: PaymentDetails): PaymentMethod => {
  const activeCreditCard = paymentDetails.creditCards?.find((card) => {
    return card.selected === true;
  });
  if (!activeCreditCard) {
    return {
      type: PaymentMethodEnum.CREDIT_CARD,
      hasActiveCreditCard: false,
      translationKey: 'payment.paymentMethod.option.creditCard',
      expiryDate: null,
      lastFourDigits: null,
    };
  }
  return {
    type: PaymentMethodEnum.CREDIT_CARD,
    hasActiveCreditCard: true,
    translationKey: 'payment.paymentMethod.option.creditCard',
    expiryDate: activeCreditCard.expirationDate,
    lastFourDigits: mapLastFourDigits(activeCreditCard.lastFourDigits),
  };
};

export function mapPaymentDetailsResponse(
  paymentDetails: PaymentDetails
): PaymentMethod {
  switch (paymentDetails.paymentMethod) {
    case 'Credit_Card_Adyen':
      return mapCreditCard(paymentDetails);
    case 'Loan_Company':
      return {
        type: PaymentMethodEnum.STUDENT_LOAN,
        translationKey: 'payment.paymentMethod.option.studentLoan',
      };
    case 'Bank_Transfer':
    default:
      return {
        type: PaymentMethodEnum.BANK_TRANSFER,
        translationKey: 'payment.paymentMethod.option.bankTransfer',
      };
  }
}
