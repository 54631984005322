<template>
  <div data-core-module="auth"></div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { setAuth0Helpers } from '@/utils/auth';

const {
  getAccessTokenSilently,
  user,
  handleRedirectCallback,
  logout,
  loginWithRedirect,
} = useAuth0();

setAuth0Helpers({
  getAccessTokenSilently,
  user,
  handleRedirectCallback,
  logout,
  loginWithRedirect,
});
</script>
