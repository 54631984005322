<template>
  <router-view v-if="loadedTranslations" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { BrandName, initialize } from '@iu/oxy';
import { i18n } from '@/utils/i18n';
import { usePaymentDetailsStore } from '@/store/paymentDetails';
import { useInvoicesStore } from '@/store/invoices';
import { captureException } from '@/utils/logging';
import { useYearsStore } from '@/store/years';

const loadedTranslations = ref(false);
const paymentDetailsStore = usePaymentDetailsStore();
const invoicesStore = useInvoicesStore();
const yearsStore = useYearsStore();

// initialize Oxy
initialize({ i18n: { locale: i18n.locale }, brand: BrandName.LIBF });

loadedTranslations.value = (
  await i18n.waitForTranslationsOfNamespace('payment')
).successful;

try {
  await Promise.all([
    paymentDetailsStore.init(),
    invoicesStore.init(),
    yearsStore.init(),
  ]);
} catch (error) {
  captureException(error);
}
</script>
