import { defineStore } from 'pinia';
import { PaymentMethod } from '@/types/PaymentMethod';
import { getPaymentDetails } from '@/api/requests';
import { captureException } from '@/utils/logging';

export const usePaymentDetailsStore = defineStore('paymentDetails', {
  state(): {
    currentPaymentMethod: PaymentMethod | null;
    isAddingNewPaymentDetails: boolean;
    successfullyAddedNewPaymentDetails: boolean | null;
  } {
    return {
      currentPaymentMethod: null,
      isAddingNewPaymentDetails: false,
      successfullyAddedNewPaymentDetails: null,
    };
  },
  actions: {
    async init() {
      try {
        this.currentPaymentMethod = await getPaymentDetails();
      } catch (error) {
        this.currentPaymentMethod = null;
        captureException(error);
      }
    },
    async refresh() {
      await this.init();
    },
    setIsAddingNewPaymentDetails(isAddingNewPaymentDetails: boolean) {
      this.isAddingNewPaymentDetails = isAddingNewPaymentDetails;
    },
    setSuccessfullyAddedNewPaymentDetails(successful = true) {
      this.successfullyAddedNewPaymentDetails = successful;
    },
    resetSuccessfullyAddedNewPaymentDetails() {
      this.successfullyAddedNewPaymentDetails = null;
    },
  },
  getters: {
    getPaymentDetails(): PaymentMethod | null {
      return this.currentPaymentMethod;
    },
  },
});
